@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);

  gt-monitor-chart {
    .up-check {
      background-color: #54a65a;
    }

    .down-check {
      background-color: mat.m2-get-color-from-palette($primary);
    }

    .no-check {
      background-color: rgba(0, 0, 0, 0.26);
    }
  }
}
