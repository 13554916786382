@use "sass:meta";
@use "@angular/material" as mat;
@use "src/assets/styles/collect-themes";
@use "src/assets/styles/themes";
@import "./assets/styles/variables";
@import "./assets/styles/font-files";
@import "./assets/styles/resets";

// partials
@import "./scss/auth-forms";
@import "./scss/data-filter-bar";
@import "./scss/nav-lists";

// Common styles for Angular Material. Included so that we only have to load a
// single CSS file for Angular Material in our app.
// Don't include this anywhere else!

@include mat.elevation-classes();
@include mat.app-background();
@include mat.all-component-typographies(themes.$custom-typography);
@include mat.card-typography(themes.$custom-card-typography);
@include mat.table-typography(themes.$custom-table-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes(themes.$candy-app-theme);
@include themes.theme-extension(themes.$candy-app-theme);

.light {
  @include meta.load-css("prismjs/themes/prism.css");
  @include collect-themes.generate-light-rules(themes.$candy-app-theme);
}

.dark {
  @include mat.all-component-colors(themes.$candy-app-theme-dark);
  @include themes.theme-extension(themes.$candy-app-theme-dark);
  @include meta.load-css("prismjs/themes/prism-twilight.css");
  @include collect-themes.generate-dark-rules(themes.$candy-app-theme-dark);
}

.light,
.dark {
  @include meta.load-css("prismjs/plugins/line-numbers/prism-line-numbers.css");
  @include meta.load-css(
    "prismjs/plugins/line-highlight/prism-line-highlight.css"
  );
}

// Responsive type maybe?
@media screen and (max-width: 767px) {
  @include mat.all-component-typographies(themes.$mobile-typography);
  @include mat.card-typography(themes.$custom-card-typography);
  @include mat.table-typography(themes.$custom-mobile-table-typography);
}

mat-card-content {
  font-family: $font-sans-serif;
}

mat-card-title {
  padding-bottom: 8px;
}

.vertical-card-header {
  flex-direction: column;
}

a {
  font-weight: 500;
}

ul {
  list-style: none;
  padding: unset;
}

svg {
  fill: currentColor;
}

.project-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax((290px), 1fr));
  grid-gap: 24px;
}

header.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  > * {
    margin: 0;
  }
}

.l-body {
  max-width: 1100px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;

  &--full-width {
    max-width: 100%;
  }

  @media screen and (max-width: $small - 1) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.u-hidden {
  display: none;
}

.mat-headline-3,
.mat-headline-4 {
  margin-bottom: 1.2em;
}

.marginless {
  margin: 0;
}

.full-width {
  width: 100%;
}

.flex-common {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.tt-wrapping {
  overflow-wrap: break-word;
  height: auto;
}

.ngx-charts-tooltip-content.type-tooltip {
  font-family: $font-sans-serif !important;
}

.line-highlight {
  max-width: 100%;
}

.mat-mdc-header-cell {
  font-size: 12px;
}

// Taken from https://stackoverflow.com/a/57759753
.small-icon-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  margin-right: 8px;
  align-items: center;
  justify-content: center;

  & > *[role="img"] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.medium-icon-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0px !important;

  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role="img"] {
    width: 24px;
    height: 24px;
    font-size: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.comment-markdown {
  ul {
    list-style: disc outside;
    padding-left: 20px;
  }

  pre {
    margin-bottom: 20px;
  }

  blockquote {
    margin: 0 0 20px 0;
    padding: 20px 20px 20px 20px;

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    blockquote:last-child {
      margin-bottom: 0;
    }
  }
}
