@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark-theme: map.get($theme, is-dark);

  gt-entry-exception {
    .highlighted {
      background: if(
        $is-dark-theme,
        mat.m2-get-color-from-palette(mat.$m2-gray-palette, 800),
        mat.m2-get-color-from-palette(mat.$m2-gray-palette, 100)
      );
    }
  }
}
