@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);

  gt-preferences {
    .invalid {
      color: mat.m2-get-color-from-palette($primary, "default");
    }
  }
}
