@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);

  gt-copy-input {
    .dsn-copied {
      color: mat.m2-get-color-from-palette($primary);
    }
  }
}
